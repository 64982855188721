<template>
  <div class="transport-map">
    <div class="transport-map__wrapper">
      <Section>
        <HeroTop :backgroundImage="require('@/assets/images/bus-view-banner.jpg')">
          <template slot="hero-top-breadcrumbs">
            <BreadCrumbs :params="{navigation, color: 'rgba(255, 255, 255, .5)'}"/>
          </template>
          <template slot="hero-top-title">
            <h1 class="hero-top__title grid-8">{{ $t('bus_view.transport') }}</h1>
          </template>
        </HeroTop>
      </Section>
      <Section limiter>
        <h2 class="page-title">{{ $t('bus_view.routes') }}</h2>
      </Section>
      <TransportMap></TransportMap>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      navigation: [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/transport-map',
        title: this.$i18n.t('menu.transport_map'),
      }],

    };
  },
};
</script>

<style lang="scss" scoped>
</style>
